<template>
  <div>
    <!--  card-table -->
    <client-language v-can="'profile.foreignLanguages.index'"></client-language>
    <!-- end card-table -->

    <!-- start Лицензии и сертификаты -->
    <clientCertificates v-can="'profile.licenses_and_certificates.index'"></clientCertificates>
    <!-- end Лицензии и сертификаты -->
    
    <!-- start Информация о здоровье -->
    <clientHealthInformation v-can="'profile.health_information.index'"></clientHealthInformation>
    <!-- end Информация о здоровье -->
    
  </div>
</template>
<script>
import ClientCertificates from "./components/client-certificates";
import ClientLanguage from "./components/client-language";
import ClientHealthInformation from "./components/client-health-information";
export default {
  components: {
    ClientCertificates,
    ClientHealthInformation,
    ClientLanguage,
  },
  data() {
    return {};
  },
};
</script>
