<template>
  <div>
    <el-row :gutter="20">
        <el-col :xs="24" :sm="6">
            <div class="ml-5 mt-5 crmhome__box">
                <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                    <div class="text-center">
                        <span :class="mode ? '' : 'textmuted__night'">{{$t('message.wage')}}</span>
                    </div>
                    <div class="text-center mt-3 profile__title" >
                        <b :class="mode ? 'text__day2' : 'text__night2'" >{{Number(activeSalaries).toLocaleString()}} сум</b>
                    </div>
                </el-card>

                <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                    <div class="text-center">
                        <span :class="mode ? '' : 'textmuted__night'">{{$t("message.limit_work_time")}}</span>
                        <!-- {{ staff_list.reports.monthly_work_hour }} -->

                    </div>
                    <div class="text-center mt-3 profile__title" v-if="staff_list&&staff_list.reports">
                      <b :class="mode ? 'text__day2' : 'text__night2'" v-if="!staff_list.reports.is_free&&staff_list.work_mounth_plane&&staff_list.work_mounth_plane.limit&&staff_list.work_mounth_plane.limit.mounth_limit">{{Math.floor(staff_list.work_mounth_plane.limit.current_limit)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.limit.current_limit - Math.floor(staff_list.work_mounth_plane.limit.current_limit))*60)}} {{$t('message.minut')}}
                       / {{Math.floor(staff_list.work_mounth_plane.limit.mounth_limit)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.limit.mounth_limit - Math.floor(staff_list.work_mounth_plane.limit.mounth_limit))*60)}} {{$t('message.minut')}}
                      </b>
                      <b :class="mode ? 'text__day2' : 'text__night2'" v-else>
                        {{Math.floor(staff_list.reports.monthly_work_hour)}} {{$t('message.hour')}} {{Math.round((staff_list.reports.monthly_work_hour - Math.floor(staff_list.reports.monthly_work_hour))*60)}} {{$t('message.minut')}}
                      
                       </b>
                    </div>
                </el-card>

                <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                    <div class="text-center">
                        <span :class="mode ? '' : 'textmuted__night'">{{$t("message.actual_working_time")}}</span>
                    </div>
                    <div class="text-center mt-3 profile__title" v-if="staff_list&&staff_list.work_mounth_plane">
                        <b style="color: #67c23a" v-if="staff_list&&staff_list.work_mounth_plane&&staff_list.work_mounth_plane.work_time>=staff_list.work_mounth_plane.limit.current_limit"> {{Math.floor(staff_list.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.work_time - Math.floor(staff_list.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                        <b style="color: red" v-else-if="staff_list&&staff_list.work_mounth_plane&&(staff_list.work_mounth_plane.work_time>0)"> {{Math.floor(staff_list.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((staff_list.work_mounth_plane.work_time - Math.floor(staff_list.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                        <b style="color: red" v-else> {{staff_list&&staff_list.work_mounth_plane?staff_list.work_mounth_plane.work_time:0}} {{$t('message.hour')}} </b>
                    </div>
                </el-card>
                <el-card class="box-card mb-3" :class="mode ? 'cardinto__day' : 'cardinto__night'">
                  <div class="text-center">
                      <span :class="mode ? '' : 'textmuted__night'">{{$t("message.accept_vacation_managment")}}</span>
                  </div>
                  <div class="text-center mt-3 profile__title">
                    <b v-if="staff_list&&staff_list.last_vacation_managment" :class="mode ? 'text__day2' : 'text__night2'">
                     {{dateFormat(staff_list.last_vacation_managment.from_date)}}<br>-<br>{{dateFormat(staff_list.last_vacation_managment.to_date)}}
                   </b>
                    <b v-else :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.not_attached")}}</b>
                 </div>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="24" :sm="18" class="crmhome__scroll">
            <table class="my-clendar mb-0"  >
                <thead>
                    <tr>
                        <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Monday")}}</div></th>
                        <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Tuesday")}}</div></th>
                        <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Wednesday")}}</div></th>
                        <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Thursday")}}</div></th>
                        <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte">{{$t("message.Friday")}}</div></th>
                        <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte" style="color:#2d2e2c7d !important;">{{$t("message.Saturday")}}</div></th>
                        <th><div :class="mode ? 'afte__day' : 'afte__night'" class="afte" style="color:#2d2e2c7d !important;">{{$t("message.Sunday")}}</div></th>
                    </tr>
                </thead>
                
            </table>
            <div class="my-calendar my-calendar_two" v-if="staff_list&&staff_list.reports && staff_list.reports.daily_reports" v-loading="loadingData">
              <div class="afte" :class="mode ? 'afte__day' : 'afte__night'"  v-for="n in (staff_list.starts_day_week-1)" :key="'last-'+n" style="grid-column: staff_list.starts_day_week / 7;" >-</div>
              <div v-for="(staff, index) in staff_list.reports.daily_reports" :key="'index-' + index"  >
                <el-popover  placement="top-start"
                    :title="staff.total_working_hour&&staff.total_working_hour!=0?(Math.floor(staff.total_working_hour)+' '+$t('message.hour')+' '+ Math.round((staff.total_working_hour - Math.floor(staff.total_working_hour))*60)+$t('message.minut')):('0 ')+$t('message.hour')"
                        width="161"
                        trigger="hover"
                        :content="'Дата: '+new Date(staff.date).toLocaleString('ru', options)"
                    >
                        <div :class="'afte ' +(staff.state=='OPENED'?(staff.is_not_working?'no_working':(staff.in_vacation?'in_vacation':'')):(staff.total_working_hour>0?(staff.total_working_hour>=(staff_list.work_mounth_plane&&staff_list.work_mounth_plane.limit?staff_list.work_mounth_plane.limit.average_work_time:8)?'yes':'no'):(staff.is_not_working?'no_working':(staff.in_vacation?'in_vacation':'off'))))"
                            slot="reference"
                            @dblclick="showStaffAttendanceInfo(staff)"
                            :style="staff.is_manual?'color:#fff; background-color:#af4562 !important; border-color: #af4562 !important':''">
                            {{index+1}}
                        </div>
                </el-popover>
              
              </div>
            </div>
            <div class="top-filter">
                <ul class="setting-left-filter">
                    <li>
                        <span></span>
                        <p :class="mode ? '' : 'textmuted__night'">{{$t('message.not_coming_day')}} </p>
                    </li>
                    <li v-if="staff_list&&staff_list.work_mounth_plane&&staff_list.work_mounth_plane.limit">
                        <span class="yes"></span>
                        <p :class="mode ? '' : 'textmuted__night'">{{ $t("message.hours_or_more", {
                            m: generateTime(staff_list.work_mounth_plane&&staff_list.work_mounth_plane.limit?staff_list.work_mounth_plane.limit.average_work_time:8),
                        })}} </p>
                    </li>
                    <li v-if="staff_list&&staff_list.work_mounth_plane&&staff_list.work_mounth_plane.limit">
                        <span class="no"></span>
                        <p :class="mode ? '' : 'textmuted__night'"> {{ $t("message.less_than_hours", {
                            m: generateTime(staff_list.work_mounth_plane&&staff_list.work_mounth_plane.limit?staff_list.work_mounth_plane.limit.average_work_time:8),
                        })}}</p>
                    </li>
                    <li>
                      <span class="off"></span>
                      <p :class="mode ? '' : 'textmuted__night'">{{$t('message.not_come_work_day')}}</p>
                    </li>
                    <li>
                        <span class="no_working"></span>
                        <p :class="mode ? '' : 'textmuted__night'">{{$t('message.day_absence')}}</p>
                    </li>
                    <li>
                      <span class="in_vacation"></span>
                      <p :class="mode ? '' : 'textmuted__night'">{{$t('message.in_vacation')}}</p>
                    </li>
                    <li>
                      <span class="manual-time"></span>
                      <p :class="mode ? '' : 'textmuted__night'">{{$t('message.manual')}}</p>
                  </li>
                </ul>
            </div>
            
        </el-col>
    </el-row>
    <el-drawer
        title="I'm outer Drawer"
        :visible.sync="showStaffAttendance"
        size="55%"
        :with-header="false"
        :with-footer="false"
        ref="show-tms"
        @opened="drawerStaffAttendanceOpened('show-staff-attendance')"
      >
        <div>
          <show-staff-attendance
            :staff="selected"
            @closeStaffAttendanceModal="closeStaffAttendanceModal"
            :child="child"
            ref="show-staff-attendance"
      ></show-staff-attendance>
  </div>
</el-drawer>
</div>
  </template>
  <script>
  import { i18n } from "@/utils/i18n";
  import { mapGetters, mapActions, mapState } from "vuex";
  import showStaffAttendance from './show-staff-attendance.vue';
  import moment from 'moment';
  export default {
    name:'mounth-report-calendar',
    props: {
        staff_id: {
          type: Number,
        },
        work_hour:{
          type:Number
        },
        activeSalaries:{
          type:Number
        },
        select_date:{
          type:String
        },
    },
    watch: {
        select_date: {
          handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.table();
                }
            },
            deep: true,
            immediate: true,
        }

    },
      components:{
        showStaffAttendance
      },
    data() {
      return {
        
        daily_reports:{},
        child: {},
        showStaffAttendance:false,
        firstDay: '',
        loadingData:false,
        selected:null,
        options : {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }
      };
    },
    mounted() {
      this.table();
    },
    computed: {
      ...mapGetters({
        staff_list: "attendanceReport/staff", 
        mode: "MODE"
      }),
      actions: function () {
       
      },
    },
    methods:{
        ...mapActions({
          updateList: "attendanceReport/oneStaff",
          updateOneStaffOlderAttendance: "attendanceReport/updateOneStaffOlderAttendance",
        }),
        generateTime(val){
          if ((val - parseInt(val))) {
            return parseInt(val) + i18n.t('message.hour') +' '+ ((val - parseInt(val)) *60).toFixed(0)+i18n.t('message.minut');
          }
          else{
            return  parseInt(val) + i18n.t('message.hour');
          }
        },
        formatMonthDate(value){
          if (value) {
            return moment((value)).lang("ru").format('MMMM YYYY')
          }
        },
        dateFormat(value){
          if (value) {
            return moment((value)).lang("ru").format('DD MMMM YYYY')
          }
        },
        table(){
           this.loadingData =true;
            const today = new Date();
            var nur = new Date(today.getFullYear(), today.getMonth(), 1)
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            this.firstDay = nur.getDay();
            const query = { id: this.staff_id, date: this.select_date?this.select_date:moment().format("YYYY-MM-DD")};
            this.updateList(query).then((res) => {
              // console.log(this.staff_list);
              this.loadingData =false;
            });
        },
        showStaffAttendanceInfo(report) {
          this.child.staff_id = report.staff_id;
          this.child.date = report.date;
          this.showStaffAttendance = true;
        },
        closeStaffAttendanceModal(val) {
          this.showStaffAttendance = val;
        },
        drawerStaffAttendanceOpened(ref) {
          if (this.$refs[ref]) {
            if (_.isFunction(this.$refs[ref].opened)) {
              this.$refs[ref].opened(this.child);
            }
          }
        },
        formatMonthDate(value){
          if (value) {
            return moment((value)).lang("ru").format('MMMM YYYY')
          }
        },
        refreshReports() {
            this.loadingData = true;
            this.updateOneStaffOlderAttendance({ id: this.staff_id})
              .then((res) => {
                this.table().then((res) => {
                  this.loadingData =false;
                });
                })
                .catch((err) => {
                  console.log(err);
                });
          }

    }
  }
  </script>
  
  <style lang="scss">
  .in_vacation{
    background: #bcbef7 !important;
  }
  .my-calendar_two{
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 1.5px;
  
  }
  .no_working {
    background-color: #2d2e2c7d !important;
    color: #fff;
  }
  .night_drawer{
    .el-drawer__body{
      background-color: #0f0e1e !important;
      overflow-x: scroll;
      overflow-y: scroll;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }      
  }
  
  </style>
  