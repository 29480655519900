<template>
    <div c-can="'profile.experience.index'">
        <work-history></work-history>
    </div>
</template>
<script>
import WorkHistory from './components/workHistory';
export default {
    components: {
        WorkHistory,
    },
    data() {
        WorkHistory
        return {};
    },  
};
</script>
