<template>
  <div>
    <div
      class="card-table mb-5"
      :class="mode ? 'cardtable__day' : 'cardtable__night'"
    >
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.licenses_and_certificates") }}</div>
        <i
          class="el-icon-plus"
          @click="ClientCertificatesCreate = true"
          v-can="'profile.licenses_and_certificates.create'"
        ></i>
      </div>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="loadClientCertificates"
      >
        <table class="w-100 td20">
          <tr>
            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.nameGiven") }}
              </strong>
            </td>

            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.gave_out") }}
              </strong>
            </td>

            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.issued") }}
              </strong>
            </td>

            <td colspan="2">
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.release_date") }}
              </strong>
            </td>
          </tr>

          <tr
            v-for="(client_certificate, index) in staffClientCertificates"
            :key="'client_certificate-' + index"
          >
            <td>
              <p
                class="font-medium mb-3 mt-3"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                <span>
                  {{ client_certificate.name }}
                </span>
              </p>
            </td>

            <td>
              <p
                class="font-medium mb-3 mt-3"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                <span>
                  {{ client_certificate.type }}
                </span>
              </p>
            </td>

            <td>
              <p
                class="font-medium mb-3 mt-3"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                <span>
                  {{ client_certificate.issued_by }}
                </span>
              </p>
            </td>

            <td>
              <p
                class="font-medium mb-3 mt-3"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                <span>
                  {{ client_certificate.issued_date }}
                </span>
              </p>
            </td>

            <td class="text-right">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editStaffWorkPlace(client_certificate)"
                  v-can="'profile.licenses_and_certificates.edit'"
                ></el-button>

                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteWorkPlace(client_certificate)"
                  v-can="'profile.licenses_and_certificates.destroy'"
                ></el-button>
              </el-row>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- start Modal  -->
    <el-dialog
      class="ichki_modal"
      title="Места Лицензии и сертификаты"
      :visible.sync="ClientCertificatesCreate"
      width="60%"
      center
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.nameGiven") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                v-model="form.name"
                :placeholder="$t('message.nameGiven')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.gave_out") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                v-model="form.type"
                :placeholder="$t('message.gave_out')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.issued") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                v-model="form.issued_by"
                :placeholder="$t('message.issued')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.release_date") }}
              </span>
              <crm-date-picker
                :date="form.issued_date"
                v-model="form.issued_date"
                type="date"
                :placeholder="$t('message.release_date')"
                :size="'medium'"
                :class="mode ? 'input__day' : 'input__night'"
              >
              </crm-date-picker>
            </div>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="submit()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="ichki_modal"
      title="Изменить Лицензии и сертификаты"
      :visible.sync="ClientCertificatesUpdate"
      width="60%"
      center
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.nameGiven") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                v-model="form.name"
                :inputValue="form.name"
                :placeholder="$t('message.nanameGivenme')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.gave_out") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                v-model="form.type"
                :inputValue="form.type"
                :placeholder="$t('message.gave_out')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.issued") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                :inputValue="form.issued_by"
                v-model="form.issued_by"
                :placeholder="$t('message.issued')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.release_date") }}
              </span>
              <crm-date-picker
                :date="form.issued_date"
                v-model="form.issued_date"
                type="date"
                :placeholder="$t('message.release_date')"
                :size="'medium'"
                :class="mode ? 'input__day' : 'input__night'"
              >
              </crm-date-picker>
            </div>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="update()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      form: {},
      loadClientCertificates: false,
      ClientCertificatesCreate: false,
      ClientCertificatesUpdate: false
    };
  },
  created() {
    this.fetchStaffClientCertificates();
  },
  computed: {
    ...mapGetters({
      staffClientCertificates: "profile/staff_client_certificates",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      getStaffClientCertificates: "profile/getStaffClientCertificates",
      addStaffClientCertificates: "profile/addStaffClientCertificates",
      uploadStaffClientCertificates: "profile/uploadStaffClientCertificates",
      deleteStaffClientCertificates: "profile/deleteStaffClientCertificates"
    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      if (this.form.staff_id) {
        this.addStaffClientCertificates(this.form)
          .then(res => {
            this.$alert(res);
            if (res.status == 201) {
              this.clearForm();
              this.fetchStaffClientCertificates();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
          });
      }
    },

    fetchStaffClientCertificates() {
      if (!this.loadClientCertificates && this.$route.params.id) {
        this.loadClientCertificates = true;
        this.getStaffClientCertificates(this.$route.params.id)
          .then(res => {
            this.loadClientCertificates = false;
          })
          .catch(err => {
            this.loadClientCertificates = false;
          });
      }
    },
    close() {
      this.clearForm();
      this.ClientCertificatesCreate = false;
      this.ClientCertificatesUpdate = false;
    },
    clearForm() {
      for (var key in this.form) {
        this.form[key] = null;
      }
    },
    editStaffWorkPlace(client_certificate) {
      this.form = JSON.parse(JSON.stringify(client_certificate));
      this.ClientCertificatesUpdate = true;
    },
    opened(refs) {
      this.$refs[refs].opened();
    },
    open(client_certificate) {
      this.form = JSON.parse(JSON.stringify(client_certificate));
      this.ClientCertificatesUpdate = true;
    },
    update() {
      this.form.staff_id = this.$route.params.id;
      this.form.client_certificate_id = this.form.id;
      if (this.form.staff_id && this.form.client_certificate_id) {
        this.loadClientCertificates = true;
        this.uploadStaffClientCertificates(this.form)
          .then(res => {
            this.$alert(res);
            this.loadClientCertificates = false;
            if (res.status == 201) {
              this.clearForm();
              this.fetchStaffClientCertificates();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
            this.loadClientCertificates = false;
          });
      }
    },
    deleteWorkPlace(client_certificate) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning"
      })
        .then(() => {
          if (!this.loadClientCertificates && this.$route.params.id) {
            this.loadClientCertificates = true;
            let form = {
              staff_id: this.$route.params.id,
              client_certificate_id: client_certificate.id
            };
            this.deleteStaffClientCertificates(form)
              .then(res => {
                this.loadClientCertificates = false;
                this.$alert(res);
                if (res.status == 200) {
                  this.fetchStaffClientCertificates();
                }
              })
              .catch(err => {
                this.loadClientCertificates = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена"
          });
        });
    }
  }
};
</script>
