<template>
  <div v-can="'profile.documents.index'">
    <div
      class="card-table mb-5"
      :class="mode ? 'cardtable__day' : 'cardtable__night'"
    >
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.file") }}</div>
        <!-- <i class="el-icon-edit"></i> -->
      </div>
      <el-button
        class="seva-document"
        type="success"
        size="small"
        @click="submit()"
        v-can="'profile.documents.create'"
      >
        {{ $t("message.save") }}
      </el-button>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="fileLoader"
      >
        <el-upload
          class="upload-demo mu-styl-doc"
          action="/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="createDocumentList"
          :file-list="documentList"
          :auto-upload="false"
        >
          <el-button
            size="small"
            type="primary"
            v-can="'profile.documents.create'"
          >
            {{ $t("message.add_document") }}
          </el-button>

          <div slot="tip" class="el-upload__tip">
            {{ $t("message.supported_formats") }}
            <br />{{ $t("message.size_limit_5") }}
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      documentList: [],
      form: {},
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      fileLoader: false
    };
  },
  created() {
    this.getDocuments();
  },
  computed: {
    ...mapGetters({
      documents: "profile/staff_documents",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      save: "profile/uploadStaffDocument",
      getDocument: "profile/getStaffDocument",
      deleteStaffDocument: "profile/deleteStaffDocument"
    }),
    handleRemove(file, doc) {
      if (
        file.hasOwnProperty("id") &&
        this.$route.params.id &&
        !this.fileLoader
      ) {
        this.fileLoader = true;
        this.deleteStaffDocument({
          staff_id: this.$route.params.id,
          file_id: file.id
        })
          .then(res => {
            this.fileLoader = false;
            if (res.status == 200) {
              this.getDocuments();
            }
            this.$alert(res);
          })
          .catch(error => {
            this.$alert(error);
            this.fileLoader = false;
          });
      } else {
        this.getDocuments();
      }
    },
    handlePreview(file) {
      window.open(file.url, "_blank");
    },
    submit() {
      if (
        !this.fileLoader &&
        this.$route.params.id &&
        _.size(this.documentList) > 0
      ) {
        this.fileLoader = true;
        let formData = new FormData();
        for (const key in this.documentList) {
          if (this.documentList[key].hasOwnProperty("url")) {
            delete this.documentList[key];
          }
          if (this.documentList.hasOwnProperty(key)) {
            const element = this.documentList[key];
            formData.append(`documents[${key}]`, element);
          }
        }

        formData["staff_id"] = this.$route.params.id;
        this.save(formData)
          .then(res => {
            this.$alert(res);
            this.fileLoader = false;
            if (res.status == 201) {
              this.getDocuments();
            }
          })
          .catch(err => {
            this.$alert(err);
            this.fileLoader = false;
            this.getDocuments();
          });
      }
    },
    getDocuments() {
      if (this.$route.params.id && !this.fileLoader) {
        this.fileLoader = true;
        this.getDocument(this.$route.params.id)
          .then(res => {
            if (res.status == 200) {
              this.documentList = [];
              for (let document in this.documents) {
                this.documentList.push({
                  uid: this.documents[document].id,
                  id: this.documents[document].id,
                  name: this.documents[document].file_name,
                  url: this.baseUrl + this.documents[document].path
                });
              }
            }
            this.fileLoader = false;
          })
          .catch(error => {
            this.$alert(error);
            this.fileLoader = false;
          });
      }
    },
    createDocumentList(file) {
      this.documentList.push(file.raw);
    }
  }
};
</script>
<style lang="scss">
.seva-document {
  position: absolute;
  left: 189px;
  top: 62px;
}
</style>
