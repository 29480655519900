<template>
  <div>
      <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
          <div class="workers-info-title-za">
              <div class="title">{{$t("message.health_information")}}</div>
              <i
                  class="el-icon-plus"
                  @click="ClientHealthInformationCreate = true"
                  v-can="'profile.health_information.create'"
              ></i>
          </div>
          <div
              class="workers-info madal-info-workers p-5 pt-2 pb-2"
              v-loading="loadClientHealthInformation"
          >
              <table class="w-100 td20">
                  <tr>
                      <td>
                          <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.growth")}} </strong>
                      </td>

                      <td>
                          <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.weight")}} </strong>
                      </td>

                      <td>
                          <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">
                             {{$t("message.blood_type")}}
                          </strong>
                      </td>

                      <td colspan="2">
                          <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">
                              {{$t("message.datas")}} 
                          </strong>
                      </td>
                  </tr>

                  <tr
                      v-for="(
                          client_health_information, index
                      ) in staffClientHealthInformation"
                      :key="'client_health_information-' + index"
                  >
                      <td>
                          <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                              <span>
                                  {{ client_health_information.height }}
                              </span>
                          </p>
                      </td>

                      <td>
                          <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                              <span>
                                  {{ client_health_information.weight }}
                              </span>
                          </p>
                      </td>

                      <td>
                          <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                              <span>
                                  {{ client_health_information.blood_group }}
                              </span>
                          </p>
                      </td>

                      <td>
                          <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                              <span>
                                  {{ client_health_information.created_at }}
                              </span>
                          </p>
                      </td>

                      <td class="text-right">
                          <el-row>
                              <el-button
                                  type="primary"
                                  icon="el-icon-edit"
                                  circle
                                  size="small"
                                  @click="
                                      editStaffClientHealthInformation(
                                          client_health_information
                                      )
                                  "
                                  v-can="'profile.health_information.edit'"
                              ></el-button>

                              <el-button
                                  type="danger"
                                  icon="el-icon-delete"
                                  circle
                                  size="small"
                                  @click="
                                      deleteClientHealthInformation(
                                          client_health_information
                                      )
                                  "
                                  v-can="'profile.health_information.destroy'"
                              ></el-button>
                          </el-row>
                      </td>
                  </tr>
              </table>
          </div>
      </div>

      <!-- start Modal  -->
      <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
          class="ichki_modal"
          :title="$t('message.locations_health_information')"
          :visible.sync="ClientHealthInformationCreate"
          width="60%"
          center
      >
          <el-form ref="form" :model="form">
              <el-row :gutter="20">
                  <el-col :span="12">
                      <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                             {{$t("message.growth")}}
                          </span>
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                              :size="'medium'"
                              v-model="form.height"
                              :placeholder="$t('message.growth')"
                              type="number"
                          ></crm-input>
                      </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="12">
                      <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.weight")}} </span>
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                              :size="'medium'"
                              v-model="form.weight"
                              :placeholder="$t('message.weight')"
                              type="number"
                          ></crm-input>
                      </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="24">
                      <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                             {{$t("message.blood_type")}}
                          </span>
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                              :size="'medium'"
                              v-model="form.blood_group"
                              :placeholder="$t('message.blood_type')"
                              type="number"
                          ></crm-input>
                      </div>
                  </el-col>
                  <!-- end col -->
              </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button type="success" size="medium" @click="submit()">
                   {{$t("message.save")}}
              </el-button>
              <el-button type="warning" size="medium" @click="close()">
                   {{$t("message.close")}}
              </el-button>
          </span>
      </el-dialog>

      <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
          class="ichki_modal"
          :title="$t('message.locations_health_information')"
          :visible.sync="ClientHealthInformationUpdate"
          width="60%"
          center
      >
          <el-form ref="form" :model="form">
              <el-row :gutter="20">
                  <el-col :span="12">
                      <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                             {{$t("message.growth")}}
                          </span>
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                              :size="'medium'"
                              v-model="form.height"
                              :inputValue="form.name"
                              :placeholder="$t('message.growth')"
                              type="number"
                          ></crm-input>
                      </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="12">
                      <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.weight")}} </span>
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                              :size="'medium'"
                              v-model="form.weight"
                              :inputValue="form.weight"
                              :placeholder="$t('message.weight')"
                              type="number"
                          ></crm-input>
                      </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="24">
                      <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                             {{$t("message.blood_type")}}
                          </span>
                          <crm-input
                          :class="mode ? 'input__day' : 'input__night'"
                              :size="'medium'"
                              :inputValue="form.blood_group"
                              v-model="form.blood_group"
                              :placeholder="$t('message.blood_type')"
                              type="number"
                          ></crm-input>
                      </div>
                  </el-col>
                  <!-- end col -->
              </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button type="success" size="medium" @click="update()">
                   {{$t("message.save")}}
              </el-button>
              <el-button type="warning" size="medium" @click="close()">
                   {{$t("message.close")}}
              </el-button>
          </span>
      </el-dialog>
  </div>
</template>

<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  components: {},
  data() {
      return {
          form: {},
          loadClientHealthInformation: false,
          ClientHealthInformationCreate: false,
          ClientHealthInformationUpdate: false,
      };
  },
  created() {
      this.fetchStaffClientHealthInformation();
  },
  computed: {
      ...mapGetters({
          staffClientHealthInformation:
              "profile/staff_client_health_information",
          mode: "MODE"

      }),
  },
  methods: {
      ...mapActions({
          getStaffClientHealthInformation:
              "profile/getStaffClientHealthInformation",
          addStaffClientHealthInformation:
              "profile/addStaffClientHealthInformation",
          uploadStaffClientHealthInformation:
              "profile/uploadStaffClientHealthInformation",
          deleteStaffClientHealthInformation:
              "profile/deleteStaffClientHealthInformation",
      }),
      submit() {
          this.form.staff_id = this.$route.params.id;
          if (this.form.staff_id) {
              this.addStaffClientHealthInformation(this.form)
                  .then((res) => {
                      this.$alert(res);
                      if (res.status == 201) {
                          this.clearForm();
                          this.fetchStaffClientHealthInformation();
                          this.close();
                      }
                  })
                  .catch((err) => {
                      this.$alert(err);
                  });
          }
      },

      fetchStaffClientHealthInformation() {
          if (!this.loadClientHealthInformation && this.$route.params.id) {
              this.loadClientHealthInformation = true;
              this.getStaffClientHealthInformation(this.$route.params.id)
                  .then((res) => {
                      this.loadClientHealthInformation = false;
                  })
                  .catch((err) => {
                      this.loadClientHealthInformation = false;
                  });
          }
      },
      close() {
          this.clearForm();
          this.ClientHealthInformationCreate = false;
          this.ClientHealthInformationUpdate = false;
      },
      clearForm() {
          for (var key in this.form) {
              this.form[key] = null;
          }
      },
      editStaffClientHealthInformation(client_health_information) {
          this.form = JSON.parse(JSON.stringify(client_health_information));
          this.ClientHealthInformationUpdate = true;
      },
      opened(refs) {
          this.$refs[refs].opened();
      },
      open(client_health_information) {
          this.form = JSON.parse(JSON.stringify(client_health_information));
          this.ClientHealthInformationUpdate = true;
      },
      update() {
          this.form.staff_id = this.$route.params.id;
          this.form.client_health_information_id = this.form.id;
          if (this.form.staff_id && this.form.client_health_information_id) {
              this.loadClientHealthInformation = true;
              this.uploadStaffClientHealthInformation(this.form)
                  .then((res) => {
                      this.$alert(res);
                      this.loadClientHealthInformation = false;
                      if (res.status == 201) {
                          this.clearForm();
                          this.fetchStaffClientHealthInformation();
                          this.close();
                      }
                  })
                  .catch((err) => {
                      this.$alert(err);
                      this.loadClientHealthInformation = false;
                  });
          }
      },
      deleteClientHealthInformation(client_health_information) {
          this.$confirm(
              "Вы действительно хотите это сделать?",
              "Предупреждение",
              {
                  confirmButtonText: "Да",
                  cancelButtonText: "Отмен",
                  type: "warning",
              }
          )
              .then(() => {
                  if (
                      !this.loadClientHealthInformation &&
                      this.$route.params.id
                  ) {
                      this.loadClientHealthInformation = true;
                      let form = {
                          staff_id: this.$route.params.id,
                          client_health_information_id:
                              client_health_information.id,
                      };
                      this.deleteStaffClientHealthInformation(form)
                          .then((res) => {
                              this.loadClientHealthInformation = false;
                              this.$alert(res);
                              if (res.status == 200) {
                                  this.fetchStaffClientHealthInformation();
                              }
                          })
                          .catch((err) => {
                              this.loadClientHealthInformation = false;
                          });
                  }
              })
              .catch(() => {
                  this.$message({
                      type: "warning",
                      message: "Операция отменена",
                  });
              });
      },
  },
};
</script>

