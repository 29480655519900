<template>
  <div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.attendance") }}</div>
        <!-- <i class="el-icon-edit"></i> -->
      </div>
      <div class="workingHours">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="caz-blocks-sarcho-title">
                <div class="block-sarche">
                  <div class="header__search my__header__search d-flex">
                    <el-date-picker
                      v-model="filterForm.punch_time_from"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      placeholder="От"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                    <el-date-picker
                      v-model="filterForm.punch_time_until"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      placeholder="До"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                    <el-button
                      :size="'small'"
                      type="primary"
                      
                      icon="el-icon-refresh-right"
                      @click="refresh()"
                      >{{ $t("message.refresh") }}</el-button
                    >
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="8" class="flex-style text-right">
              <div class="data-hr" :class="mode ? '' : 'text__night2'">
                <i class="el-icon-date"></i> {{ $t("message.today") }}
                {{ currentTime }}
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="card-table-header table-crm-smart">
          <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
            <thead>
              <tr>
                <th class="w50p">{{ $t("message.n") }}</th>
                <th>{{ $t("message.device") }}</th>
                <th>{{ $t("message.time") }}</th>
                <th>{{ $t("message.attendance_type") }}</th>
                <th>{{ $t("message.verfired_type") }}</th>
                <th>{{ $t("message.img") }}</th>
                <th>{{ $t("message.created_at") }}</th>
                <th>{{ $t("message.updated_at") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(attendance, index) in list"
                :key="'attendance' + index"
              >
                <td :class="attendance.device?'':'td-warning'" class="w50p">{{ attendance.id }}</td>
                <td :class="attendance.device?'':'td-warning'">{{ attendance.device?attendance.device.device_name: $t('message.manual') }}</td>
                <!-- <td :class="attendance.device?'td-success':'td-warning'">{{ attendance.punch_time }}</td> -->
                <td :class="attendance.device?(mode ? 'td-success' : ''):'td-warning'" >
                  <el-button type="success" size="mini" icon="el-icon-time" round>
                    {{ attendance.punch_time }}
                  </el-button>
                </td>
                <td :class="attendance.device?'':'td-warning'">
                  {{ attendance.punch_state }}
                </td>
                <td :class="attendance.device?'':'td-warning'">{{ attendance.verify_type }}</td>
                <td :class="attendance.device?'':'td-warning'">
                  <el-image
                    style="width: 40px; height: 40px"
                    :src="
                      attendance.captured_img
                        ? baseUrl + attendance.captured_img
                        : 'img/avatar.jpeg'
                    "
                    :preview-src-list="[
                      attendance.captured_img
                        ? baseUrl + attendance.captured_img
                        : 'img/avatar.jpeg',
                    ]"
                  >
                  </el-image>
                </td>
                <td :class="attendance.device?'':'td-warning'">{{ attendance.created_at }}</td>
                <td :class="attendance.device?'':'td-warning'">{{ attendance.updated_at }}</td>
              </tr>
            </tbody>
          </table>
          <div class="my___pagination">
            <crm-pagination
              @c-change="updatePagination"
              :class="mode ? 'pagination__day' : 'pagination__night'"
              :pagination="pagination"
            ></crm-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      filterForm: {},
      loadingData: false,
      currentTime: moment().format("DD.MM.YYYY HH:mm"),
    };
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updatePagination({ key: "page", value: 1 });
        await this.updateFilter(newVal);
        this.debouncedFetchData();
      },
      deep: true,
      immediate: true,
    },
    sort: {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    "pagination.page": {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    "pagination.per_page": {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.filter.staff_id = this.$route.params.id;
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.debouncedFetchData = _.debounce(this.fetchData, 500);
    setInterval(() => this.updateCurrentTime(), 1000);
  },
  computed: {
    ...mapGetters({
      list: "attendance/staffAttendance",
      pagination: "attendance/paginationAttendance",
      filter: "attendance/filter",
      sort: "attendance/sort",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      updatePagination: "attendance/updatePaginationAttendance",
      updateFilter: "attendance/updateFilter",
      updateColumn: "attendance/updateColumn",
      refreshData: "attendance/refreshData",
      updateSort: "attendance/updateSort",
      updateList: "attendance/staffAttendance",
      delete: "attendance/destroy",
      empty: "attendance/empty",
      show: "attendance/show",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    updateCurrentTime() {
      this.currentTime = this.getCurrentTime();
    },
    getCurrentTime() {
      return moment().format("DD.MM.YYYY HH:mm");
    },
    refresh() {
      this.playLoadSound(
        "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
      );
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    playLoadSound(url) {
      let audio = new Audio(url);
      audio.play();
    },
  },
};
</script>
